




















import { defineComponent } from '@vue/composition-api'
import { GenericObject } from '@/inc/types'

export default defineComponent({
  name: 'SpecificPanel',
  props: {
    content: {
      type: Object as () => GenericObject,
      required: true,
    },
  },

  setup(props) {
    return {
      title: props.content.title,
      html: props.content.htmltext,
    }
  },
})
