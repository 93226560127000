









































































































































































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import VueRecaptcha from 'vue-recaptcha'

import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import gtm, { Event } from '@/composables/gtm'

import VCheckbox from '@/components/form/VCheckbox.vue'
import VInput from '@/components/form/VInput.vue'
import { getApiUrl } from '@/inc/app.config'
import axios from 'axios'
import SpecificPanel from '@/components/connection/panels/specificPanel.vue'

/* eslint-enable camelcase */
/* eslint-disable */
export default defineComponent({
  name: 'intervention',
  components: {
    'v-header': Header,
    Breadcrumb,
    VueRecaptcha,
    VCheckbox,
    VInput,
    SpecificPanel,
  },
  setup(_props, ctx) {
    const { content } = useGetters(['content'])
    const chrome = useState(['chrome'])
    const interventionState = useState('intervention', ['i18n'])
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const interventionActions = useActions('intervention', ['fetchI18n'])
  
    const i18n = ref(interventionState.i18n)
    const gdprConsent = ref(false)
    const creditConsent = ref(false)
    const isloading = ref(false)
    const bceLength = ref(19)
    const numContractLengthError = ref(false)
    const bce = ref('BE')
    const errorBce = ref(false)
    const name = ref('')
    const prenom = ref('')
    const numContract = ref('33')
    const error = ref('')
    const isSubmit = ref(false)
    const recaptcha = ref('')
    const errorNum = ref(false)
    const recaptchaKey = ref(chrome.chrome.value.data.recaptcha)
    const A = ref('A'.charCodeAt(0))
    const Z = ref('Z'.charCodeAt(0))
    const marginBottomLight = [
      'text_shortcodes',
      'text_only',
      'text_title',
      'pictureTitle',
    ]

    onBeforeMount(() => {
      interventionActions.fetchI18n()
    })

    let bceShow = computed({
      get() {
        return ctx.root.$options?.filters?.VMask(
          bce.value,
          'BE## #### #### #####'
        )
      },
      set(val: string) {
        bce.value = val.replace(/^BE|\//, '')
      },
    })

    watch(bce, newVal => {
      /*if (bce.value.substring(0, 2) !== 'BE') {
        bce.value = 'BE'
      }*/
      bce.value = bceShow.value.trim()
      if (bce.value.length === 19) {
        newVal.trim()
        errorBce.value = !ibanValid(bce.value.replaceAll(' ', ''))
      } else {
        errorBce.value = false
      }

      if (
        newVal.charCodeAt(newVal.length - 1) >= 48 &&
        newVal.charCodeAt(newVal.length - 1) <= 57
      ) {
      } else {
        if (newVal.length >= 3) {
          const valuesArray = newVal.split('')
          valuesArray.splice(newVal.length - 1, newVal.length, '')
          const updateValue = valuesArray.join('')
          bce.value = updateValue
        }
      }
    })
    const checkButton = invalid => {
      if (
        name.value &&
        prenom.value &&
        numContract.value &&
        bce.value.replaceAll(' ', '').length === 16 &&
        !errorBce.value &&
        !invalid &&
        recaptcha.value &&
        gdprConsent.value &&
        creditConsent.value &&
        numContract.value.length === 9
      ) {
        return false
      } else {
        return true
      }
    }

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }

    const showSpecificPanel = e => {
      e.preventDefault()

      axios
        .get(`${getApiUrl()}/faq/intervention-ponctuelle-2021/`)
        .then(({ data }) => {
          showPanel({
            component: {
              template: SpecificPanel,
            },
            content: data.content,
          })
        })
        .catch(error => {
          console.log(error)
          console.log('Fetch FAQ fail')
        })
    }
    const submitData = () => {
      isSubmit.value = true
      isloading.value = true
      errorNum.value = false
      axios
        .post(`${getApiUrl()}/form/refund`, {
          Nom: name.value,
          Prenom: prenom.value,
          NumContrat: numContract.value,
          IBAN: bce.value,
        })
        .then(resp => {
          isloading.value = false
          if (resp.status === 200) {
            isSubmit.value = true
            sendFormEvent()
          }
        })
        .catch(error => {
          isSubmit.value = false
          isloading.value = false

          if (error.response.data.originalBody.ErrorCode === 'BAD_CONTRACT') {
            errorNum.value = true
          }
          resetRecaptcha()
        })
      //ScrollTop
      ctx.root.$nextTick(() => {
        const el = document.querySelector('.intervention')
        if (el) {
          el.scrollTop = el.scrollHeight
        }
        window.scroll({
          top: 20,
          behavior: 'smooth',
        })
      })
    }
    const iso13616Prepare = iban => {
      iban = iban.toUpperCase()
      iban = iban.substr(4) + iban.substr(0, 4)

      return iban
        .split('')
        .map(function (n) {
          var code = n.charCodeAt(0)
          if (code >= A.value && code <= Z.value) {
            // A = 10, B = 11, ... Z = 35
            return code - A.value + 10
          } else {
            return n
          }
        })
        .join('')
    }
    const iso7064Mod97_10 = iban => {
      var remainder = iban,
        block

      while (remainder.length > 2) {
        block = remainder.slice(0, 9)
        remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length)
      }

      return parseInt(remainder, 10) % 97
    }
    const ibanValid = iban => {
      iban = iban.toUpperCase()
      if (iban.substr(0, 2) === 'BE') {
        if (iso7064Mod97_10(iso13616Prepare(iban)) === 1) {
          return true
        }
      }
      return false
    }
    watch(numContract, newVal => {
      errorNum.value = false
      numContract.value = newVal
      if (numContract.value.substring(0, 2) !== '33') {
        numContract.value = '33'
      }
      if (numContract.value.length === 8 || numContract.value.length === 10) {
        numContractLengthError.value = false
      } else {
        numContractLengthError.value = true
      }
      if (
        newVal.charCodeAt(newVal.length - 1) >= 48 &&
        newVal.charCodeAt(newVal.length - 1) <= 57
      ) {
      } else {
        if (newVal.length >= 3) {
          const valuesArray = newVal.split('')
          valuesArray.splice(newVal.length - 1, newVal.length, '')
          const updateValue = valuesArray.join('')
          numContract.value = updateValue
        }
      }
    })

    const sendFormEvent = () => {
      const event = {
        event: 'formulaire-intervention-submission',
      } as Event
      gtm.sendEvent(event)
    }

    return {
      showSpecificPanel,
      submitData,
      content,
      marginBottomLight,
      recaptchaKey,
      gdprConsent,
      creditConsent,
      bce,
      bceLength,
      name,
      prenom,
      numContract,
      setRecaptcha,
      resetRecaptcha,
      error,
      errorBce,
      isSubmit,
      recaptcha,
      i18n,
      numContractLengthError,
      checkButton,
      errorNum,
      isloading,
    }
  },
})
